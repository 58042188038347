@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
  color: inherit;
}

img {
  object-fit: fill;
}

button {
  text-transform: none !important;
}
.Sidebar-Link:hover span {
  /* background-color: rgba(44, 169, 227, 0.25) !important; */
  /* border-left: 8px solid #2ca9e3 !important; */
  color: #c0c0c0;
}
.Sidebar-Link:hover img {
  /* border-bottom: 1px solid #fff; */
}
/* ///////////////react toastify notifications/////////// */
.Toastify__toast--success {
  background-color: #fff !important;
}
.Toastify__toast--error {
  background-color: #fff !important;
}
.Toastify__toast--warning {
  background-color: #fff !important;
}
.Toastify__toast--info {
  background-color: #fff !important;
}
.Toastify__toast--success .Toastify__toast-body {
  color: #10a44b;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}
.Toastify__toast--info .Toastify__toast-body {
  color: #2f7ec7;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}
.Toastify__toast--error .Toastify__toast-body {
  color: #e03a3a;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}
.Toastify__toast--warning .Toastify__toast-body {
  color: #db9d64;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

.Toastify__toast {
  width: 400px;
}
